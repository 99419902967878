package com.findyourbias.web.pages.yourbias

import androidx.compose.runtime.Composable
import com.findyourbias.web.pages.yourbias.Biases.biases
import com.varabyte.kobweb.core.Page
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Page("/your-bias")
@Composable
fun YourBias() {
    val randomBias = biases.random()

    BiasDetail(randomBias)


}

@Composable
fun BiasDetail(bias: Bias) {
    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
            height(100.vh)
            width(100.vw)
            fontFamily("CustomFont, sans-serif")
            textAlign("center")
            fontFamily()
        }
    }) {
        H1(
            attrs = {
                style {
                    color(Color("#B37B72"))
                    fontFamily("CustomFont, sans-serif")
                    fontSize(48.px)
                }
            }
        ) {
            Text("Senin biasın ${bias.name}")
        }
        Img(src = bias.imageUrl, attrs = {
            style {
                width(200.px) // Resmin genişliğini ayarlayın
                height(200.px) // Resmin yüksekliğini ayarlayın
                borderRadius(50.percent)
                marginBottom(16.px)
            }
        })
        P(
            attrs = {
                style {
                    fontFamily("fonts/arcade-classic.ttf")
                    color(Color("#B37B72"))
                    fontSize(36.px)
                }
            }
        ) {
            Text(bias.description)
        }
    }
}
