package com.findyourbias.web.pages.selectmood

import androidx.compose.runtime.Composable
import com.findyourbias.web.pages.yourbias.Bias
import com.findyourbias.web.pages.yourbias.Biases.biases
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.browser.window
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Page("/select-mood")
@Composable
fun SelectMood() {
    Style(SelectMoodStyleSheet)

    Div(attrs = {
        classes(SelectMoodStyleSheet.container)
    }) {
        ButtonGrid()
    }
}

@Composable
fun ButtonGrid() {
    val context = rememberPageContext()

    Div(attrs = {
        classes(SelectMoodStyleSheet.gridContainer)
    }) {
        listOf(
            "DUYGUSAL", "DOBRA", "FEDAKAR",
            "MERAKLI", "TUTUMLU", "SABIRLI",
            "YARGILAYICI", "MANTIKLI", "LIDER",
            "BECERIKLI", "PRATIK", "BASARILI"
        ).forEach { text ->
            BandButton(text) {
                context.router.navigateTo("/your-bias")
            }
        }
    }
}

@Composable
fun BandButton(text: String, onClick: () -> Unit) {
    Button(attrs = {
        classes(SelectMoodStyleSheet.button)
        onClick { onClick() }
    }) {
        Text(text)
    }
}