package com.findyourbias.web.pages.index

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text


@Page("/bahar")
@Composable
fun Home() {

    val context = rememberPageContext()

    Style(HomeStylesheet)

    Div(attrs = {
        classes(HomeStylesheet.container)
    }) {
        Div(attrs = {
            classes(HomeStylesheet.centeredBox)
        }) {
            Button(attrs = {
                classes(HomeStylesheet.button)
                onClick {
                    context.router.navigateTo("/select-band")
                }
            }) {
                Text("BIASINI BUL")
            }
        }
    }
}