package com.findyourbias.web.pages.index

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text


@Page("/")
@Composable
fun Index() {
    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
            height(100.vh)
            width(100.vw)
            textAlign("center")
        }
    }) {
        H1 {
            Text("Hoşgeldiniz!")
        }
        P {
            Text("Bu, Find Your Bias uygulamasının ana sayfasıdır.")
        }
    }
}
