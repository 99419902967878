package com.findyourbias.web.pages.selectmood

import org.jetbrains.compose.web.css.*

object SelectMoodStyleSheet : StyleSheet() {
    val container by style {
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        height(100.vh)
        width(100.vw)
    }

    val gridContainer by style {
        display(DisplayStyle.Grid)
        gridTemplateColumns("repeat(3, 1fr)")  // 3 sütun
        gap(36.px)
        padding(36.px)
    }

    val button by style {
        borderRadius(24.px)
        border(0.px)
        color(Color.white)
        fontSize(24.px)
        cursor("pointer")
        fontFamily("fonts/ARCADECLASSIC")
        width(200.px)  // Sabit genişlik
        height(50.px)  // Sabit yükseklik
        backgroundColor(rgb(156, 44, 62))  // Arka plan rengi

        self + hover style {
            opacity(0.8)
        }
    }
}