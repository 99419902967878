package com.findyourbias.web.pages.selectband

import org.jetbrains.compose.web.css.*

object SelectBandStyleSheet : StyleSheet() {
    val container by style {
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        height(100.vh)
        width(100.vw)
    }

    val gridContainer by style {
        display(DisplayStyle.Grid)
        gridTemplateColumns("1fr 1fr")
        gap(16.px)
    }

    val column by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        alignItems(AlignItems.Center)
        padding(36.px)
        gap(36.px)
    }

    val button by style {
        borderRadius(24.px)
        border(0.px)
        color(Color.white)
        fontSize(24.px)
        cursor("pointer")
        fontFamily("fonts/ARCADECLASSIC")
        width(300.px) // Sabit genişlik
        height(70.px) // Sabit yükseklik

        self + hover style {
            opacity(0.8)
        }
    }
}