package com.findyourbias.web.pages.index

import org.jetbrains.compose.web.css.*

object HomeStylesheet : StyleSheet() {
    val container by style {
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        height(100.vh)
        width(100.vw)
        backgroundImage("url('images/background.png')")
        backgroundSize("cover")
        backgroundPosition("center")
        padding(16.px)
        boxSizing("border-box")
    }

    val centeredBox by style {
        backgroundColor(rgb(235, 169, 186))
        padding(64.px)
        borderRadius(8.px)
        textAlign("center")
    }

    val button by style {
        backgroundColor(rgb(219, 112, 147))
        color(Color.white)
        fontSize(32.px)
        padding(32.px)
        border(0.px)
        borderRadius(8.px)
        cursor("pointer")
        fontFamily("fonts/ArcadeClassic")

        hover(self) style {
            backgroundColor(rgb(199, 92, 127))
        }
    }
}