package com.findyourbias.web.pages.yourbias

object Biases {
    val biases = listOf(
        Bias("Han", "images/han.png", "Hazır cevapsın"),
        Bias("Felix", "images/felix.png", "Sanatsal bir ruhun var"),
        Bias("Changbnn", "images/changbnn.png", "Tatlı ve enerjiksin"),
        Bias("Jeongin", "images/jeongin.png", "Lider ruhlu ve koruyucusun"),
        Bias("Chan", "images/chan.png", "Sessiz ama derin birisin"),
        Bias("Huncin", "images/huncin.png", "Çalışkan ve disiplinlisin"),
        Bias("Minho", "images/minho.png", "Neşeli ve iyimser birisin"),
        Bias("Seung", "images/seung.png", "Nazik ve düşüncelisin"),
    )
}