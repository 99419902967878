package com.findyourbias.web.pages.selectband

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Page("/select-band")
@Composable
fun SelectBand() {
    Style(SelectBandStyleSheet)

    Div(attrs = {
        classes(SelectBandStyleSheet.container)
    }) {
        ButtonGrid()
    }
}

@Composable
fun ButtonGrid() {

    val context = rememberPageContext()

    Div(attrs = {
        classes(SelectBandStyleSheet.gridContainer)
    }) {
        // Left column
        Div(attrs = { classes(SelectBandStyleSheet.column) }) {
            listOf("STRAY KIDS" to "#D43A3D", "BTS" to "#9459C8", "BLACK PINK" to "#DD68C1").forEach { (text, color) ->
                BandButton(text, color) {
                    context.router.navigateTo("/select-mood")
                }
            }
        }

        // Right column
        Div(attrs = { classes(SelectBandStyleSheet.column) }, ) {
            listOf("NCT" to "#117FEA", "MONSTA X" to "#07AA34", "TWICE" to "#E1973A").forEach { (text, color) ->
                BandButton(text, color) {
                    context.router.navigateTo("/select-mood")
                }
            }
        }
    }
}

@Composable
fun BandButton(text: String, backgroundColor: String, onClick: () -> Unit) {
    Button(attrs = {
        style {
            property("background-color", backgroundColor)
        }
        classes(SelectBandStyleSheet.button)
        onClick { onClick() }
    }) {
        Text(text)
    }
}